import styled from 'styled-components';
import React from 'react';
import { Fade } from 'react-reveal';
import {
  Padding,
  Section,
} from '@/features/new-landing-page/components/section';
import { StrapiTextual } from '@/features/strapi/components/textual';
import { PartnersBenefitsContent } from '@/features/new-landing-page/pages/partners-content';
import { StrapiPartnersBenefitCard } from '@/features/strapi/components/partner-benefit-card';
import { VSpace } from '@/components/spacing';
import { breakpoints } from '@/components/responsive';

export function PartnersBenefitsSection() {
  const firstRow = PartnersBenefitsContent.logos.slice(0, 5);
  const secondRow = PartnersBenefitsContent.logos.slice(5);

  return (
    <Section>
      <VSpace value={6} mobileValue={3} />
      <Padding padding="0 3rem">
        <StrapiTextual {...PartnersBenefitsContent.title} />
        <VSpace value={3} mobileValue={2} />
      </Padding>
      <Fade bottom>
        <Padding padding="0 4rem">
          <StrapiTextual {...PartnersBenefitsContent.subTitle} />
        </Padding>
      </Fade>
      <VSpace value={3} />
      <Benefits>
        {PartnersBenefitsContent.benefits.map((benefit) => (
          <Fade bottom>
            <StrapiPartnersBenefitCard key={benefit.title} {...benefit} />
          </Fade>
        ))}
      </Benefits>
      <VSpace className="only-desktop" value={16} />
      <PartnersRow1 className="only-desktop">
        <Fade bottom>
          {firstRow.map((logo) => (
            <img key={logo} src={logo} alt="" />
          ))}
        </Fade>
      </PartnersRow1>
      <VSpace className="only-desktop" value={4} />
      <PartnersRow2 className="only-desktop">
        <Fade bottom>
          {secondRow.map((logo) => (
            <img key={logo} src={logo} alt="" />
          ))}
        </Fade>
      </PartnersRow2>
      <VSpace className="only-mobile" value={4} />
      <PartnersList className="only-mobile">
        {PartnersBenefitsContent.logos.map((logo) => (
          <Fade bottom key={logo}>
            <img key={logo} src={logo} alt="" />
          </Fade>
        ))}
      </PartnersList>
      <VSpace value={5} mobileValue={3} />
    </Section>
  );
}

const Benefits = styled.div`
  width: 80%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-content: center;

  grid-gap: 4rem;

  ${breakpoints.down(`md`)} {
    width: 90%;
    grid-template-columns: 1fr;
    grid-gap: 2rem;
  }
`;

const PartnersRow1 = styled.div`
  width: 70%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  justify-content: center;
  align-content: center;
  grid-gap: 8rem;

  img {
    height: 4rem;
    width: auto;
    object-fit: contain;
    justify-self: center;
  }

  img:nth-child(1) {
    height: 5rem;
  }
`;

const PartnersRow2 = styled.div`
  width: 70%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  justify-content: center;
  align-content: center;
  grid-gap: 4rem;

  img {
    height: 3.5rem;
    width: auto;
    object-fit: contain;
    justify-self: center;
  }
`;

const PartnersList = styled.div`
  width: 90%;
  display: grid;
  grid-template-columns: 1fr;
  justify-content: center;
  align-content: center;
  grid-gap: 8rem;

  img {
    height: 5rem;
    width: auto;
    object-fit: contain;
    justify-self: center;
  }

  img:nth-child(1) {
    height: 6rem;
  }

  img:nth-child(5) {
    height: 7rem;
  }

  img:nth-child(6),
  img:nth-child(7),
  img:nth-child(8) {
    height: 3.5rem;
  }
`;
