import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import {
  Padding,
  Section,
} from '@/features/new-landing-page/components/section';
import { StrapiTextual } from '@/features/strapi/components/textual';
import {
  PartnersBenefitsContent,
  PartnersMainBannerContent,
} from '@/features/new-landing-page/pages/partners-content';
import { VSpace } from '@/components/spacing';
import { StrapiPartnerReviewCard } from '@/features/strapi/components/partner-review-card';
import { PartnersJoinForm } from '@/features/new-landing-page/sections/partners-join-form';
import { Marquee } from '@/features/new-landing-page/components/marquee';
import { GradientGlowContainer } from '@/features/new-landing-page/components/gradient-glow-container';

export function PartnersMainBannerSection() {
  const [isModalOpen, setIsModalOpen] = React.useState(false);

  return (
    <Section>
      <GradientGlowContainer duration={30} />
      <VSpace value={1} mobileValue={0.1} />
      <StaticImage
        className="only-desktop"
        height={70}
        src="../assets/partners.png"
        alt="partners logo"
        loading="eager"
        placeholder="tracedSVG"
      />
      <StaticImage
        className="only-mobile"
        height={50}
        src="../assets/partners.png"
        alt="partners logo"
        loading="eager"
        placeholder="tracedSVG"
      />
      <StrapiTextual {...PartnersMainBannerContent.title} />
      <Padding padding="0 2rem">
        <StrapiTextual {...PartnersMainBannerContent.subTitle} />
      </Padding>
      <VSpace value={2} />
      <StrapiTextual
        style={{ maxWidth: `60%` }}
        onClick={() => setIsModalOpen(true)}
        {...PartnersMainBannerContent.cta}
      />
      <VSpace value={3} mobileValue={4} />
      <StrapiPartnerReviewCard {...PartnersMainBannerContent.review} />
      <VSpace value={5} />
      {isModalOpen && (
        <PartnersJoinForm onClose={() => setIsModalOpen(false)} />
      )}
      <Marquee gap="6rem" duration={40} className="only-mobile">
        {PartnersBenefitsContent.logos.map((logo) => (
          <img
            key={logo}
            src={logo}
            alt=""
            style={{ height: `4rem`, width: `auto` }}
          />
        ))}
      </Marquee>
      <VSpace className="only-mobile" value={5} />
    </Section>
  );
}
