import React from 'react';
import { GlobalStyles } from '@/styles/globalStyles';
import { Header } from '@/features/new-landing-page/components/header';
import { Footer } from '@/features/new-landing-page/components/footer';
import { PageWrapper } from '@/features/new-landing-page/pages/homepage';
import { PartnersBenefitsSection } from '@/features/new-landing-page/sections/partners-benefits';
import { PartnersJoinSection } from '@/features/new-landing-page/sections/partners-join';
import { PartnersMainBannerSection } from '@/features/new-landing-page/sections/partners-main-banner';

export function Partners() {
  return (
    <PageWrapper>
      <GlobalStyles />
      <Header />
      <PartnersMainBannerSection />
      <PartnersBenefitsSection />
      <PartnersJoinSection />
      <Footer />
    </PageWrapper>
  );
}
