import styled from 'styled-components';
import React from 'react';
import { AiOutlineClose } from 'react-icons/ai';
import { StrapiTextual } from '@/features/strapi/components/textual';
import Portal from '@/components/portal';
import { VSpace } from '@/components/spacing';
import { HubspotForm } from '@/features/new-landing-page/components/hubspot-form';
import { breakpoints } from '@/components/responsive';

const formCss = `
  input[type="submit"] {
    width: 100%!important;
  }
`;

export interface PartnersJoinFormProps {
  onClose?: () => void;
}

export function PartnersJoinForm({ onClose }: PartnersJoinFormProps) {
  return (
    <Portal selector="body">
      <Backdrop onClick={onClose}>
        <Wrapper onClick={(ev) => ev.stopPropagation()}>
          <CloseButton onClick={onClose}>
            <AiOutlineClose size={25} />
          </CloseButton>
          <div className="header">
            <VSpace value={2} mobileValue={0.1} />
            <StrapiTextual
              size="3rem"
              weight="bold"
              text="Join the Visually Partner Program"
              mobileMultiplier={0.65}
            />
            <VSpace value={4} mobileValue={2} />
          </div>

          <HubspotForm
            formId="92975f04-1dad-478f-90af-7b5178872c12"
            portalId="143410811"
            submitText="Become a Partner"
            additionalCss={formCss}
          />
        </Wrapper>
      </Backdrop>
    </Portal>
  );
}

const Backdrop = styled.div`
  z-index: 10 !important;
  position: fixed;
  inset: 0;
  height: 100vh;
  width: 100vw;
  background: rgba(0, 0, 0, 0.3);
  backdrop-filter: blur(8px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const Wrapper = styled.div`
  max-height: 90vh;
  min-height: 30vh;
  position: relative;
  display: grid;
  grid-template-rows: auto 1fr;

  border: 1px solid rgba(151, 151, 151, 0.1);
  border-radius: 2.5rem;
  background-color: #ffffff;
  box-shadow: 0 4px 7px 0 rgba(0, 0, 0, 0.18),
    0 9px 13px 0 rgba(177, 217, 203, 0.18), 0 1px 4px 0 rgba(0, 0, 0, 0.05);
  padding: 3rem 10rem;

  ${breakpoints.down(`md`)} {
    max-height: 95vh;
    height: 95vh;
    padding: 3rem 3rem 0 3rem;
    border-radius: 1.5rem;
  }

  .hubspot-form {
    overflow-y: scroll;
    mask-image: linear-gradient(
      var(from top, to bottom),
      hsl(0 0% 0% / 0),
      hsl(0 0% 0% / 1) 5%,
      hsl(0 0% 0% / 1) 95%,
      hsl(0 0% 0% / 0)
    );
  }

  .header {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .footer {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .gatsby-image-wrapper {
    pointer-events: none;
  }
`;

const CloseButton = styled.div`
  position: absolute;
  top: 2rem;
  right: 2rem;

  ${breakpoints.down(`md`)} {
    top: 1rem;
    right: 1rem;
  }

  opacity: 0.5;
  cursor: pointer;
  &&:hover {
    opacity: 0.8;
  }

  &&:active {
    opacity: 0.6;
  }
`;
