import React from 'react';
import styled from 'styled-components';
import { StrapiTextual } from '@/features/strapi/components/textual';
import { breakpoints } from '@/components/responsive';

export interface StrapiPartnerReviewCardProps {
  image: string;
  name: string;
  job: string;
  logo: string;
  text: string;
}

export function StrapiPartnerReviewCard({
  image,
  name,
  job,
  logo,
  text,
}: StrapiPartnerReviewCardProps) {
  return (
    <Wrapper>
      <Left>
        <img className="photo" src={image} alt={name} />
        <StrapiTextual
          className="name"
          text={name}
          weight="bold"
          size="2rem"
          lineHeight="1.8"
        />
        <StrapiTextual
          className="job"
          text={job}
          weight="light"
          size="1.4rem"
          lineHeight="1.8"
        />
        <img className="logo" src={logo} alt={name} />
      </Left>
      <Right>
        <StrapiTextual
          className="text"
          text={text}
          weight="normal"
          size="2.4rem"
          lineHeight="1.8"
          mobileMultiplier={0.65}
        />
      </Right>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  max-width: 70%;
  padding: 5rem 4rem;
  display: grid;
  grid-gap: 2rem;
  grid-template-columns: 1fr 2fr;
  border: 1px solid rgba(151, 151, 151, 0.1);
  border-radius: 4rem;
  box-shadow: 0 4px 7px 0 rgba(0, 0, 0, 0.18),
    0 9px 13px 0 rgba(177, 217, 203, 0.18), 0 1px 7px 0 rgba(0, 0, 0, 0.05);

  ${breakpoints.down(`md`)} {
    padding: 3rem;
    border-radius: 2rem;
    max-width: 90%;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr;
    grid-gap: 1rem;
  }
`;

const Left = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  justify-self: center;

  ${breakpoints.down(`md`)} {
    align-items: center;
    justify-self: center;
  }

  img.photo {
    height: 10rem;
    width: 10rem;
    object-fit: contain;
    border-radius: 50%;
    background: #969696;
  }

  img.logo {
    height: 3rem;
    width: auto;
    object-fit: contain;
    margin-top: 1rem;

    ${breakpoints.down(`md`)} {
      margin-top: 2rem;
      margin-left: -1.5rem;
    }
  }

  .name {
    margin-top: 2rem;
  }

  .job {
    text-align: start;
  }

  .text {
    padding-right: 4rem;
  }
`;
const Right = styled.div``;
