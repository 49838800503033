import React from 'react';
import styled from 'styled-components';
import { StrapiTextual } from '@/features/strapi/components/textual';
import { VSpace } from '@/components/spacing';

export interface StrapiPartnerBenefitCardProps {
  title: string;
  image: string;
  text: string;
}

export function StrapiPartnersBenefitCard({
  title,
  image,
  text,
}: StrapiPartnerBenefitCardProps) {
  return (
    <Wrapper>
      <div className="header">
        <StrapiTextual size="2.4rem" weight="bold" text={title} />
        <img src={image} alt="" />
      </div>
      <VSpace value={3} />
      <StrapiTextual size="2.4rem" text={text} lineHeight="1.8" />
    </Wrapper>
  );
}

const Wrapper = styled.div`
  height: 100%;
  border: 1px solid rgba(151, 151, 151, 0.1);
  border-radius: 3rem;
  background-color: #f7f9fb;
  padding: 5rem;

  .header {
    display: grid;
    grid-template-columns: 1fr auto;
  }

  img {
    height: 3rem;
    width: auto;
    object-fit: contain;
  }

  && * {
    text-align: start;
    letter-spacing: -0.4px;
  }
`;
