import styled from 'styled-components';
import React from 'react';
import { Section } from '@/features/new-landing-page/components/section';
import { StrapiTextual } from '@/features/strapi/components/textual';
import { PartnersJoinContent } from '@/features/new-landing-page/pages/partners-content';
import { VSpace } from '@/components/spacing';
import { PartnersJoinForm } from '@/features/new-landing-page/sections/partners-join-form';
import { breakpoints } from '@/components/responsive';

export function PartnersJoinSection() {
  const [isModalOpen, setIsModalOpen] = React.useState(false);

  return (
    <CustomSection>
      <VSpace value={9} mobileValue={4} />
      <StrapiTextual {...PartnersJoinContent.title} />
      <VSpace value={5} />
      <StrapiTextual
        className="dis-btn-primary"
        {...PartnersJoinContent.cta}
        onClick={() => setIsModalOpen(true)}
      />
      <VSpace value={5} />
      <VSpace value={10} mobileValue={4} />
      {isModalOpen && (
        <PartnersJoinForm onClose={() => setIsModalOpen(false)} />
      )}
    </CustomSection>
  );
}

const CustomSection = styled(Section)`
  && {
    background: #dc4949 !important;
    color: white;

    && > * {
      margin-bottom: 0;
    }

    .dis-btn-primary {
      padding: 1.5rem 10rem;
      font-size: 2.4rem;
      color: black;
      background: white;
      border-radius: 3rem;
      border: 2px solid white;

      ${breakpoints.down(`md`)} {
        width: 50%;
        font-size: 1.4rem;
        padding: 1rem 2rem;
      }
    }
  }
`;
